.form_details label {
  display: block;
  margin-bottom: 1px;
}

.sigCanvas {
  border: 1px solid black;
}

.elementor-show-phone {
  display: none;
}

@media screen and (max-width: 767px) {
  .elementor-show-phone {
    display: block;
  }
}

/* 
@media screen and (max-width: 991px) {
  .menu-item-has-children :hover>.sub-menu {
    display: block;
  }
} */

/* .main-navigation ul.navbar-nav .menu-item-has-children:hover>.sub-menu,
.main-navigation ul.navbar-nav .menu-item-has-children:active>.sub-menu {
  display: block !important;
} */

@media (hover: hover) {

  .main-navigation ul.navbar-nav .menu-item-has-children:hover>.sub-menu {
    display: block;
  }
}